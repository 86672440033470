import React from "react"
import Container from "react-bootstrap/Container"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import "../pages/styles/faq.styles.scss"
import Footer from "../components/Layout/Footer"
import NavBarLight from "../components/Layout/navBar/NavBarLight"

const Portal = () => (
  <>
    <NavBarLight />
    <Container fluid className="faqs-main-container p-0">
      <Container className="pt-5">
        <h1 className="pt-4 h1-header text-white pt-5"> FAQ's</h1>
        <h3 className="m-0 h3-sub-header mb-3">
          {" "}
          About your Pave Line of Credit{" "}
        </h3>
        <Accordion defaultActiveKey="0" className="mb-5">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              WHAT IS A LINE OF CREDIT?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                A line of credit provides a set credit limit that you can draw
                from, pay back, and draw from again. When you request funds,
                these are called “draws” or “advances”. With Pave Credit, you
                can apply for a line of credit from $400 to $3,000. You
                determine how much you want to borrow from your available credit
                limit and when you want to draw from your available credit,
                allowing you to control your finances. You’ll have a minimum
                payment due each billing cycle, but you’re always allowed to pay
                more. We will never charge you for paying off what you owe
                early. The faster you pay down your outstanding balance, the
                less interest you will accumulate and the more credit will be
                available for future draws. You are allowed to make additional
                draws before you have fully repaid your initial draw, if there
                is available credit on your account and you make all of your
                payments on time. Each draw will accumulate interest until
                repaid.
                <br />
                <br />
                Here’s an example: Let’s say you’re approved for a $1,000 line
                of credit. If you use $300 for an emergency car repair, then
                you’ll still have $700 in available credit. You will accumulate
                interest only on the $300 you have drawn. Your payments will
                cover both interest and principal, so your available balance
                will increase with each payment until the total due on the $300
                draw is repaid and your credit limit is back up to $1,000.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              IS A LINE OF CREDIT THE SAME THING AS A PAYDAY LOAN?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                No. A payday loan is a short-term loan that must be repaid in
                full, with interest, on your next payday or payment deadline. A
                line of credit is different. You can request advances in
                amount(s) up to your approved credit limit and pay interest only
                on the advance(s) you receive. A line of credit gives you more
                flexibility by allowing you to make payments only on the amount
                that you choose to advance. As long as your account remains in
                good standing, your line of credit remains available to draw
                from again. There is no need to re-apply to receive additional
                funds after your initial advance, simply check your available
                credit limit before requesting an additional draw.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              HOW MUCH CAN I BORROW?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Pave Credit offers lines of credit ranging from $400-3,000. The
                amount of your approved line of credit will depend largely on
                your credit history. If you are approved for a Pave Credit line
                of credit, you can draw any amount from a minimum of $50.00 up
                to your available credit limit.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              WHAT DO I NEED TO BEGIN MY APPLICATION?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                To apply for a Pave Credit line of credit, you must be a legal
                resident of a state within the United States where Pave Credit
                lines of credit are available. To begin the application process,
                you will need your:
                <br />
                <br />
                <ul>
                  <li>Social security number</li>
                  <li>Physical address (and mailing address if different)</li>
                  <li>
                    State Issued Driver’s license (or other form of government
                    issued ID)
                  </li>
                  <li>Proof of current employment</li>
                  <li>
                    Bank account information (both routing and account number)
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              CAN I APPLY FOR A PAVE CREDIT LINE OF CREDIT IF MY CREDIT ISN’T SO
              GREAT?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                Yes. We look at more than just your credit score when we make
                our decision about your eligibility to receive a Pave Credit
                line of credit. Though your credit score does play an important
                part, we examine your credit history as a whole. Even if you’ve
                been turned down by other lenders, we may be able to help you
                obtain a line of credit that fits your needs.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              CAN I APPLY FOR MULTIPLE LINES OF CREDIT?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                At this time, Pave Credit offers only one line of credit per
                household.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              WHAT KIND OF BANK ACCOUNT IS REQUIRED TO APPLY FOR A LINE OF
              CREDIT?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                You will need a checking account that allows ACH transfers to
                apply for a Pave Credit line of credit. Savings accounts and
                prepaid debit cards are not currently accepted.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="9">
              HOW WILL I KNOW IF I’VE BEEN APPROVED?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="9">
              <Card.Body>
                Once your application has been reviewed and approved by our
                underwriting team, you will receive a notice from us via e-mail
                informing you as to whether your application has been approved
                and your total credit limit.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="10">
              HOW SOON CAN I RECEIVE FUNDS ONCE I’M APPROVED?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="10">
              <Card.Body>
                If you are approved for a Pave Credit line of credit, you can
                request an advance once you have reviewed and signed your Line
                of Credit Agreement via the customer portal. It typically takes
                up to two business days for your funds to be deposited into your
                bank account, but may take additional time in case of weekends,
                holidays, or bank/institution delays.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="11">
              CAN I WITHDRAW MONEY FROM MY LINE OF CREDIT BEFORE I PAY WHAT I
              CURRENTLY OWE?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="11">
              <Card.Body>
                Yes. You can make multiple draws as long as you don’t exceed
                your credit limit and as long as you are not in default.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="12">
              WHEN WILL MY PAYMENTS BE DUE?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="12">
              <Card.Body>
                Payment due dates will be identified on the periodic statements
                that Pave Credit sends to you via e-mail.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="13">
              WHAT IF I DON’T HAVE ENOUGH MONEY IN MY BANK ACCOUNT TO COVER A
              PAYMENT?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="13">
              <Card.Body>
                We get it. Sometimes things happen. When they do, we’re happy to
                help you. Just call us at least two (2) business days prior to
                your next payment due date at (855) 801-PAVE between the hours
                of 8 am and 8 pm Eastern time Monday through Friday, or shoot us
                an e-mail at{" "}
                <a
                  style={{ color: "white", fontWeight: "bold" }}
                  href="mailto:support@pavecredit.com"
                >
                  support@pavecredit.com
                </a>
                . We’ll work with you to come up with the best solution to
                address your issue.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="14">
              I’M HAVING TROUBLE PAYING MY PAVE CREDIT BILL. WHAT KIND OF HELP
              IS AVAILABLE?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="14">
              <Card.Body>
                Please feel free to reach out to us regarding any hardships you
                might have that affect your ability to pay your Pave Credit
                bill. Just call us at (855) 801-PAVE between the hours of 8 am
                and 8 pm Eastern time Monday through Friday, or shoot us an
                e-mail at{" "}
                <a
                  style={{ color: "white", fontWeight: "bold" }}
                  href="mailto:support@pavecredit.com"
                >
                  support@pavecredit.com
                </a>
                . We’ll do our best to help out any way we can.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="15">
              HOW DO I UPDATE MY BANK INFORMATION?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="15">
              <Card.Body>
                You can log in to the customer portal and update your bank
                information there. You may also contact a member of our support
                team by calling at (855) 801-PAVE between the hours of 8 am and
                8 pm Eastern time Monday through Friday, or sending us an e-mail
                at
                <a
                  style={{ color: "white", fontWeight: "bold" }}
                  href="mailto:support@pavecredit.com"
                >
                  support@pavecredit.com
                </a>
                .
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="16">
              HOW CAN I SCHEDULE A PAYMENT ONLINE?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="16">
              <Card.Body>
                If you’d like to schedule a payment in addition to your
                regularly scheduled minimum payment due, you can use the
                customer portal as well. However, scheduling an additional
                payment will not cancel any upcoming regular minimum payments
                due.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="17">
              HOW DO I MAKE CHANGES TO MY ACCOUNT PROFILE?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="17">
              <Card.Body>
                It’s easy! If you would like to update your account information,
                such as email address, phone number, or mailing address, you can
                log in to the customer portal to update your profile. You may
                also contact a member of our support team by calling at (855)
                801-PAVE between the hours of 8 am and 8 pm Eastern time Monday
                through Friday, or sending us an e-mail at{" "}
                <a
                  style={{ color: "white", fontWeight: "bold" }}
                  href="mailto:support@pavecredit.com"
                >
                  support@pavecredit.com
                </a>
                .
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="18">
              ARE PAVE CREDIT LINES OF CREDIT SECURE AND CONFIDENTIAL?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="18">
              <Card.Body>
                Absolutely! We utilize industry-standard security protocols to
                ensure every Pave Credit line of credit transaction is secure
                and confidential and protected by our Data Security policy.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="19">
              WHAT IS PAVE CREDIT’S PRIVACY POLICY?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="19">
              <Card.Body>
                You may view the Pave Credit privacy policy{" "}
                <a
                  href="/docs/Salt%20Point%20Finance%20dba%20Pave%20Credit_Privacy%20Policy_compressed.pdf"
                  target="_blank"
                >
                  here
                </a>
                .
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <div className="row py-5">
          <div className="col-12 mx-auto col-md-5 text-center">
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = "https://application.pavecredit.com/"
                return true
              }}
              className="w-100 p-3"
            >
              Apply now
            </Button>
          </div>
        </div>
      </Container>
    </Container>
    <Footer />
  </>
)

export default Portal
